import React from 'react';
import Helmet from 'react-helmet';

const containerStyle = {
    height: '100vh',
    margin: '5em',
    textAlign: 'center',
    fontFamily: "'Open Sans',sans-serif",
    lineHeight: '24px',
};

const ConfirmSubscription = () => (
    <>
        <Helmet>
            <meta name="robots" content="noindex,nofollow" />
            <title>React-admin newsletter subscription</title>
        </Helmet>
        <div style={containerStyle}>
            <p>
                Success! You are now subscribed to the react-admin newsletter.
            </p>
            <p>We are excited to have you onboard.</p>
            <p>
                You can close this window, or continue browsing{' '}
                <a href="https://marmelab.com/react-admin">
                    the react-admin site
                </a>
                .
            </p>
        </div>
    </>
);

export default ConfirmSubscription;
